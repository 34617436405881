
import LoginForm from "../components/login-form";
import TermsAndConditionsModal from "../components/terms-and-conditions-modal";
import { mapActions } from "vuex";
import { Component, Vue } from "vue-property-decorator";

interface UserForm {
  username: string;
  password: string;
}

@Component({
  components: {
    LoginForm,
    TermsAndConditionsModal
  },
  data() {
    return {
      modal: false
    };
  },
  methods: {
    ...mapActions(["handleLogin", "getUserInfo"])
  }
})
export default class Login extends Vue {
  handleSubmit(userForm: UserForm) {
    this.api.user.sessions
      .login(userForm)
      .then(response => {
        if (response) {
          const token = response.data.token;
          const user = response.data.user;
          this.$store.commit("login", { user: user, token: token });
          this.$router.push("/dashboard");
        }
      })
      .catch(() => {});
  }
  forgetPassword() {
    this.$Modal.info({
      title: "哎呀",
      content: "现阶段暂不支持找回密码。请直接联系尚学顾问申请修改密码。"
    });
  }
}
