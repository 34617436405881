<template>
  <Form
    ref="loginForm"
    :model="form"
    :rules="ruleInline"
    @keydown.enter.native="handleSubmit"
  >
    <FormItem prop="username">
      <Input
        v-model="form.username"
        placeholder="请输入用户名"
        autocomplete="username"
      >
        <span slot="prepend">
          <Icon :size="16" type="ios-person"></Icon>
        </span>
      </Input>
    </FormItem>

    <FormItem prop="password">
      <Input
        type="password"
        v-model="form.password"
        password
        placeholder="请输入密码"
        autocomplete="password"
      >
        <span slot="prepend">
          <Icon :size="14" type="md-lock"></Icon>
        </span>
      </Input>
    </FormItem>
    <FormItem>
      <Button @click="handleSubmit" type="primary" long>登录</Button>
    </FormItem>
  </Form>
</template>

<script>
export default {
  name: "LoginForm",
  data() {
    const validUsername = (rule, value, callback) => {
      if (
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          value
        )
      ) {
        callback("用户名不是邮箱喔");
      } else {
        callback();
      }
    };
    return {
      form: {
        username: "",
        password: ""
      },
      ruleInline: {
        username: [
          { required: true, message: "账号不能为空", trigger: "blur" },
          { validator: validUsername, trigger: "blur" }
        ],
        password: [{ required: true, message: "密码不能为空", trigger: "blur" }]
      }
    };
  },
  methods: {
    handleSubmit() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.$emit("on-success-valid", {
            username: this.form.username,
            password: this.form.password
          });
        }
      });
    }
  }
};
</script>
